import {CountUp} from "countup.js";

let has_animated = new Array<Element>();

function animate_countup() {
	//@ts-ignore
	let $ = jQuery || window.jQuery
	let container_elements = Array.from(document.getElementsByClassName("stats-container"));
	container_elements.forEach((container_element) => {
		if (has_animated.indexOf(container_element) == -1){
			let position = container_element.getBoundingClientRect();
			if (position.top >= 0 && position.bottom <= window.innerHeight || position.top < window.innerHeight) {
				has_animated.push(container_element)
				let elements = Array.from(container_element.querySelectorAll(".stat-value"))
				elements.forEach((element) => {
					let numerical_value = Number(element.getAttribute("data-number-value"));
					let countUp = null;
					if (!Number.isInteger(numerical_value)){
						countUp = new CountUp((element as HTMLElement),numerical_value, {duration: 3, decimalPlaces: 2, separator: ".", decimal: ","});
					}else{
						countUp = new CountUp((element as HTMLElement),numerical_value, {duration: 3, separator: ".", decimal: ","});
					}
					countUp.start();
				})
			}}
	})

}

window.addEventListener('load', animate_countup);
window.addEventListener('scroll', animate_countup);
